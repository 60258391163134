define('ember-dynamic-component/views/dynamic-component', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var get      = Ember['default'].get;
  var observer = Ember['default'].observer;
  var on       = Ember['default'].on;

  exports['default'] = Ember['default'].ContainerView.extend(Ember['default']._Metamorph, {
    type: null,

    _changeChild: on('init', function() {
      this.clear();
      this.pushObject(this.componentForType());
    }),

    changeChild: observer('type', function() {
      this._changeChild();
    }),

    componentForType: function() {
      var type = get(this, 'type');

      var container       = get(this, 'container');
      var componentLookup = container.lookup('component-lookup:main');
      var customComponent = componentLookup.lookupFactory(type, container);

      var hash    = get(this, "_dynamicOptions");
      var ignore  = ["templateData", "_dynamicOptions", "_context", "_parentView", "helperName", "container"];
      var props   = {};

      var prop, bindingTarget;
      for (prop in hash) {
        if (ignore.indexOf(prop) !== -1) { continue; }
        if (prop.match(/Binding$/)) {
          bindingTarget = prop.replace("Binding", '');
          props[prop]   = "_parentView."+bindingTarget;
        } else {
          props[prop] = hash[prop];
        }
      }

      return this.createChildView(customComponent, props);
    }
  });

});